var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"timeline-card"},[_c('div',{staticClass:"label-title",class:{
      'title-margin': _vm.vm.currentPhase !== 'pre-mint',
    }},[_vm._v(" "+_vm._s(_vm.vm.currentPhase === 'pre-mint' ? 'How to participate' : _vm.vm.currentPhase === 'airdrop' ? 'Airdrop timeline' : 'Sale timeline')+" ")]),(!_vm.vm.mintState)?_c('v-skeleton-loader',{staticClass:"full-height",attrs:{"type":"card","light":""}}):_c('div',[_c('div',{staticClass:"d-flex flex-column gap-6"},_vm._l((_vm.vm.mintTimeline),function(item,index){return _c('div',{key:index,staticClass:"position-relative"},[_c('div',{staticClass:"d-flex",class:_vm.$vuetify.breakpoint.lgAndUp ? 'gap-2' : ''},[_c('v-radio-group',{staticClass:"mt-2",staticStyle:{"min-width":"32px","min-height":"32px"},attrs:{"mandatory":index <= _vm.vm.mintState.timelineStep}},[(!item.isClaim)?_c('v-radio',{attrs:{"readonly":"","color":"primary"}}):_vm._e()],1),_c('div',{staticClass:"stage-title d-flex flex-column gap-2"},[_c('div',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(item.name))]),_c('div',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm._f("yyyymmddhhmm")(item.time)))]),(item.description)?_c('div',{staticClass:"grey--text"},_vm._l((item.description),function(des,i){return _c('span',{key:i,class:{
                  'white--text font-weight-bold': !!des.isBold,
                }},[_vm._v(_vm._s(des.text))])}),0):_vm._e()])],1),(
            index < _vm.vm.mintTimeline.length - 1 && (!_vm.vm.mintTimeline[index + 1] || !_vm.vm.mintTimeline[index + 1].isClaim)
          )?_c('div',{class:{
            'active-line': index < _vm.vm.mintState.timelineStep - (_vm.vm.mintState.showProcessWaiting ? 1 : 0),
            'line-dotted': !item.isTooLongTitle,
            'line-dotted-xl': item.isTooLongTitle,
          }}):_vm._e()])}),0)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }