


























































import { Observer } from 'mobx-vue'
import { Component, Inject, Vue } from 'vue-property-decorator'
import { MintViewModel } from '../viewmodels/mint-viewmodel'

@Observer
@Component({
  components: {},
})
export default class extends Vue {
  @Inject({}) vm!: MintViewModel
}
